import React, { useMemo, Suspense } from 'react'
import NoSsr from '@material-ui/core/NoSsr'
import { graphql } from 'gatsby'
import * as R from 'ramda'
import { StoryblokComponent } from '@sb-konzept/gatsby-source-storyblok'
import PageContext from 'components/PageContext'
import PageLayout from 'components/PageLayout'
import FullScreenLoader from 'components/FullScreenLoader'

function Page (props) {
  const { data } = props

  const {
    page: {
      content: { body, layout }
    }
  } = data

  const { header, footer } = useMemo(
    () =>
      R.applySpec({
        header: R.pathOr(null, [0, 'content', 'header', 0]),
        footer: R.pathOr(null, [0, 'content', 'footer', 0])
      })(layout),
    [layout]
  )

  return (
    <PageContext.Provider value={{ page: data.page }}>
      {header && <StoryblokComponent content={header} />}
      <PageLayout>
        <NoSsr>
          <Suspense fallback={<FullScreenLoader />}>
            {body?.map(content => (
              <StoryblokComponent
                key={content.id || content._uid}
                content={content}
              />
            ))}
          </Suspense>
        </NoSsr>
      </PageLayout>
      {footer && <StoryblokComponent content={footer} />}
    </PageContext.Provider>
  )
}

export default Page

export const pageQuery = graphql`
  query($id: String!) {
    page: storyblokPageStory(id: { eq: $id }) {
      ...storyblokPageStory
    }
  }
`
